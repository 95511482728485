import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import NextButton from '../NextButton';
import ActionButton from '../ActionButton';
import './styles.scss';

const Schedule = () => {
  const [toBooking, setToBooking] = useState(false);
  if (toBooking) return (<Redirect to="/app" />);
  return (
    <>
      <div className="shell-background">
        <div className="dark-layer shell-content">
          <div className="container schedule-box d-flex align-items-end justify-content-center">
            <div className="row pt-md-5 text-white">
              <div className="col-12 text-center">
                <h2>Agenda tu cambio de aceite fácil y rápido</h2>
                <h3>Elige dónde, cuándo, tu auto ¡y listo!</h3>
                <ActionButton text='¡Agenda aquí!' actionFunction={() => { setToBooking(true); }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NextButton arrowColor='yellowArrow' sectionId='#features' />
    </>
  );
};

export default Schedule;
