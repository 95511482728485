import React, { useState, useEffect } from 'react';
import ActionButton from '../ActionButton';
import maptutorial from './assets/maptutorial.png'
import productutorial from './assets/productutorial.jpg'
import './styles.scss';

const KnowOilPrice = () => {
    const [openTutorial, setOpenTutorial] = useState(true);
    const [toggleFade, setToggleFade] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpenTutorial(prevCheck => !prevCheck)
        }, 200)
    }, [toggleFade])

    const ListCol = ({ number, text, content }) => {
        return (
            <>
                <div className="col-12 mt-3 d-flex flex-row align-items-center">
                    <div className="number-circle d-flex text-whie text-white align-items-center justify-content-center mr-3 shell-bold">{number}</div>
                    <strong className="mb-0 gray-text findlub-text">{text}</strong>
                </div>
                <div className="col-12 mt-3 gray-text mb-3">
                    {content && content}
                </div>
            </>
        );
    }

    return (
        <div className="container pt-2" id="knowoilprice">
            <div className="text-center">
                <ActionButton text='¿Cúal es el valor del cambio de aceite?' actionFunction={() => setToggleFade(prevCheck => !prevCheck)} animatedArrow={true} />
            </div>
            <div className={toggleFade ? "fadeIn" : "fadeOut "} >
                {openTutorial &&
                    <>
                    <div className="row pt-4">
                        <div className="col my-auto">
                            <ListCol number='1' text='Selecciona la estación de servicios donde quieres realizar tu cambio de aceite' />
                        </div>
                        <div className="col-12 col-lg-7 m-auto">
                            <img src={maptutorial} className="tutorial-content-img" alt={maptutorial} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col my-auto">
                           <ListCol number='2' text='Encontrarás en la parte inferior de la página los valores del servicio en la estación seleccionada'/>
                            </div>
                        <div className="col-12 col-lg-7 m-auto">
                                <img src={productutorial} className="tutorial-content-img" alt={productutorial} />
                        </div>
                    </div>
                    <div className="container pt-4">
                        <p className="legalInformation">* Precio de referencia bidón de 4 litros, incluye mano de obra. El servicio no incluye filtro y el valor de este dependerá del modelo del vehículo</p>
                        <p className="legalInformation">* Los precios pueden variar dependiendo de la estación de servicios seleccionada</p>    
                    </div>
                    </>
                }
            </div>
        </div>

    );
};

export default KnowOilPrice;
